import axios from 'axios';
import { MODEL_TITLE, PYTHON_API_URL } from '../constants/ArrayData';

const aurelia_api_uri = PYTHON_API_URL[MODEL_TITLE.indexOf("Aurélia")] + "/aurelia/" //*/ "http://127.0.0.1:8000/pricing/"
const token = String(localStorage.getItem("token"))

const AxiosInstanceCreator = (BASE_URL: string, TOKEN: string = token) => {
  // Configuration globale d'Axios
  const instance = axios.create({
    baseURL: BASE_URL, // Remplacez par votre URL de base
    timeout: 20000, // Durée maximale d'attente pour la réponse en millisecondes
    headers: {
      'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`,
      // Vous pouvez ajouter d'autres en-têtes ici si nécessaire
    },
  });

  // Intercepteur pour les requêtes
  instance.interceptors.request.use(
    (config) => {
      // Vous pouvez ajouter des manipulations avant l'envoi de la requête ici
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Intercepteur pour les réponses
  instance.interceptors.response.use(
    (response) => {
      // Vous pouvez ajouter des manipulations avant de renvoyer la réponse ici
      return response;
    },
    (error) => {
      // Vous pouvez gérer les erreurs ici
      return Promise.reject(error);
    }
  );
  
  return instance;
}

export const verifyUserTokenValidation = async () => {
  
  try{
    const instance = AxiosInstanceCreator(aurelia_api_uri);
    const response = await instance.get(`verifyUserToken/${token}`);
    return response
  } catch(error) {
      return error
  }
};