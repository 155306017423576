import React from "react";
import { useParams } from "react-router-dom";

import "../styles/Information.css"
import { MESSAGE, MODEL_TITLE } from "../constants/ArrayData";
import { Redirect2NewPage } from "../Helpers/Redirect2";

const GenerateInformation = () => {

    const params = useParams();
    const llm_name = params.model_name
    const index = MODEL_TITLE.indexOf(String(llm_name))
    const user_id = localStorage.getItem('userID')

    if(index === -1) {
        window.location.replace('*');
    }
    const text = MESSAGE[index]

    return (
        <div className="info-content-container">
            <h1>Message de {llm_name}</h1>
            <p id="text-p">{text}</p>
            <button className="info-btn-2-home" onClick={()  => window.close()}>D'accord</button>
        </div>
    )
}

export default GenerateInformation