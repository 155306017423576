import React from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { ItemsType } from "../../type/UserDataEvent";

import "../../styles/InputSearchAutocomplete.css"

interface ISearchAutoC {
    items: Array<ItemsType>,
    itemSelected: (item: ItemsType) => void;
    Placeholder?: string;
    zindexValue?: number;
}

const SearchAutocomplete: React.FC<ISearchAutoC> = ({items, itemSelected, Placeholder, zindexValue = 6}) => {
    const handleOnSearch = (string: any, results: any) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        // console.log("handle search: ", string, results)
        // string = ""
      }
    
      const handleOnHover = (result: any) => {
        // the item hovered
        // console.log(result)
      }
    
      const handleOnSelect = (item : any) => {
        // the item selected
        // console.log("Select res: ", item)
        itemSelected(item)
      }
    
      const handleOnFocus = () => {
        // console.log('Focused')
      }
    
      const formatResult = (item: any) => {
        // console.log("Format res: ", item)
        return (
          <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.title}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
          </>
        )
      }

    return (
        <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            styling={{
              zIndex: zindexValue
            }}
            autoFocus
            className="search-custom-auto-comp"
            placeholder={Placeholder}
            showNoResultsText="Aucun résultat"
            formatResult={formatResult} />
    )
}

export default SearchAutocomplete