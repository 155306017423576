import React, { useEffect, useState } from "react";
import Sso from "./Sso";
import DashRedirection from "./Aurelia/AurDashRedPage";
import { USER_ID_KEY } from "../constants/StoreKey";

const PageChecker = () => {
    const login_state = localStorage.getItem(USER_ID_KEY)
    const [need2LoggedIn, setNeed2LoggedIn] = useState(false)
    const [canGo2Home, setCanGo2Home] = useState(false)
    
    useEffect(() => {
        setTimeout(() => {
            if(login_state) {
                setCanGo2Home(true)
            }else{
                setNeed2LoggedIn(true)
            }
        }, 2000)
    }, [login_state])

    if(login_state !== null && login_state !== undefined){
        return <DashRedirection />
    }
    else if(canGo2Home) {
        return <DashRedirection />
    }
    else if(need2LoggedIn){
        return <Sso />
    }
    else{
        return (
            <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                {/* <LoaderResponse colorValue={COLORS.jauneClaire} w={130} h={130}/> */}
            </div>
        )
    }
    
}

export default PageChecker