import React, { useState } from "react";
import {MailFormat4View} from "../interface/DataI";
import { INCITATION_DATA } from "../constants/ArrayData";

import "../styles/Combobox.css"
import { INCITATION_KEY } from "../constants/StoreKey";

interface ComboboxProps {
    data: string;
    getOptionIndex: any;
    OptionData?: Array<MailFormat4View>;
}

const ComboBox:React.FC<ComboboxProps> = ({data, getOptionIndex, OptionData = INCITATION_DATA}) => {
    const [incitationData, setIncitationData] = useState("");

    const updateIncitationReponse = (val: React.ChangeEvent<HTMLSelectElement>) => {
        setIncitationData(val.target.value)
        localStorage.setItem(INCITATION_KEY,val.target.value)
        getOptionIndex(val.target.value)
    }

    const SelectView = (Data: Array<MailFormat4View> | undefined) => {
        let comboboxView: Array<JSX.Element> = []

        if(Data) {
            // Data = INCITATION_DATA
            for(let idx = 0; idx < Data.length; idx++){
                let content = Data[idx]['content']
                let optionView: Array<JSX.Element> = [
                    <option key={-idx - 1} value={""}>{"--Veuillez choisir--"}</option>
                ];

                if(data){
                    optionView.pop()
                }

                for(let opt_idx=0; opt_idx < content.length; opt_idx++){
                    optionView.push(
                        <option key={idx + opt_idx + 1} value={content[opt_idx]}>{content[opt_idx]}</option>
                    )
                }
                comboboxView.push(
                    <div className="mail-prep-combobox" key={idx}>
                        <label>{Data[idx]['title']}</label>
                        <select id="custom-select" value={incitationData} onChange={(val: React.ChangeEvent<HTMLSelectElement>) => updateIncitationReponse(val)} required={true}>
                            {optionView}
                        </select>
                    </div>
                )
                optionView = []
        }

        return comboboxView
        }
    }
    
    return (
        <>
            <div className="mail-prep-right-text">
                {SelectView(OptionData)}
            </div>
        </>
    )
}

export default ComboBox