import "../styles/NotFound.css"
import { Redirect2NewPage } from "../Helpers/Redirect2";

const ErrorPage = () => {
    return (
        <div className="error-content-container">
            <p id="error-title">Oops! Vous semblez être perdu.</p>
            <h1>404 - Page introuvable</h1>
            <p style={{padding: 10}}>Voici un lien pour revenir dans le bon chemin:</p>
            <button className="error-btn-2-home" onClick={()  => Redirect2NewPage("accueil")}>Revenir à la page d'accueil</button>
        </div>
    )
}

export default ErrorPage