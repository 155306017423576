import * as React from 'react';
import Box from '@mui/material/Box';
import "../../styles/Table.css"

import { DataGrid, GridColDef, GridRowsProp, gridPageSizeSelector } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
import { COLORS } from '../../constants/ColorsData';
import { UserCampagneData } from '../../interface/DataI';

const getBackgroundColor = (color: string, mode: string, val: number = 0.7) =>
    mode === 'dark' ? darken(color, val) : lighten(color, val);

const getHoverBackgroundColor = (color: string, mode: string, val: number = 0.6) =>
    mode === 'dark' ? darken(color, val) : lighten(color, val);

const getSelectedBackgroundColor = (color: string, mode: string, val: number = 0.5) =>
    mode === 'dark' ? darken(color, val) : lighten(color, val);

const getSelectedHoverBackgroundColor = (color: string, mode: string, val: number = 0.4) =>
    mode === 'dark' ? darken(color, val) : lighten(color, val);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--row1': {
        backgroundColor: getBackgroundColor(
            COLORS.grisSombre,
            theme.palette.mode,
            0.2
        ),
        color: "white",
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                COLORS.jauneClaire,
                theme.palette.mode,
            ),
            cursor: "pointer", color: "black"
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--row2': {
        backgroundColor: getBackgroundColor(
            COLORS.grisClaire,
            theme.palette.mode,
            0.2
        ),
        color: "black",
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                COLORS.jauneClaire,
                theme.palette.mode,
            ),
            cursor: "pointer", color: "black"
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Open': {
        backgroundColor: getBackgroundColor(
            COLORS.gris,
            theme.palette.mode,
            0.2
        ),
        fontSize: 16,
        fontWeight: 500,
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                COLORS.jauneClaire,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Filled': {
        backgroundColor: getBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--PartiallyFilled': {
        backgroundColor: getBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--Rejected': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const TableauStyle: React.FC<UserCampagneData> = ({data}) => {
    const [row1, setRow1] = React.useState("row1")
    const [row2, setRow2] = React.useState("row2")

    const rows: GridRowsProp = data
    
    const rows_d: GridRowsProp = [
        {
            id: 1,
            mail_title: "Titre du mail 1",
            name: "Nom du destinataire1",
            job: "Poste N° 1",
            mail_status: "Lu",
            date: "Date 1",
            status: row1
        },
        {
            id: 2,
            mail_title: "Titre du mail 2",
            name: "Nom du destinataire2",
            job: "Poste N° 2",
            mail_status: "Non ouvert",
            date: "Date 2",
            status: row2
        },
        {
            id: 3,
            mail_title: "Titre du mail 3",
            name: "Nom du destinataire3",
            job: "Poste N° 3",
            mail_status: "Ouvert",
            date: "Date 3",
            status: row1
        },
        {
            id: 4,
            mail_title: "Titre du mail 4",
            name: "Nom du destinataire4",
            job: "Poste N° 4",
            mail_status: "Non lu",
            date: "Date 4",
            status: row2
        }
    ];

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', headerAlign: 'left', width: 70, headerClassName: 'super-app-theme--Open', },
        { field: 'mail_title', headerName: 'Titre du mail', headerAlign: 'left', width: 150, headerClassName: 'super-app-theme--Open' },
        { field: 'name', headerName: 'Déstinataire', headerAlign: 'left', width: 200, headerClassName: 'super-app-theme--Open' },
        // { field: 'organization', headerName: 'Entreprise', headerAlign: 'left', width: 200, headerClassName: 'super-app-theme--Open' },
        { field: 'job', headerName: 'Poste', headerAlign: 'left', width: 150, headerClassName: 'super-app-theme--Open' },
        { field: 'mail_status', headerName: 'Status du mail', headerAlign: 'left', width: 200, headerClassName: 'super-app-theme--Open' },
        { field: 'date', headerName: 'Date d\'envoi', headerAlign: 'left', width: 200, headerClassName: 'super-app-theme--Open' },
        // { field: 'status', headerName: 'Status', headerAlign: 'left', width: 150}
    ];

    return (
        <Box sx={{ 
            height: '100%', width: '100%',
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
            },
        }}>
            <StyledDataGrid
                columns={columns} rows={rows} 
                onColumnHeaderOver={() => {
                    // setRow1("Open")
                    // setRow2("Open")
                    }
                }
                onColumnHeaderLeave={() => {
                    // setRow1("row1")
                    // setRow2("row2")
                    }
                }
                getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                // pageSizeOptions={[25, 50, 100 ]}
                autoPageSize={true}
            />
        </Box>
    );
}

export default TableauStyle