import axios from 'axios';
import { MODEL_TITLE, PYTHON_API_URL } from '../constants/ArrayData';

const aurelia_uri = PYTHON_API_URL[MODEL_TITLE.indexOf("Aurélia")] //*/ "http://127.0.0.1:8001/sf_api/"
const apollo_uri = String(process.env.REACT_APP_APOLLO_CRM_BASE_URL)
const salesforce_uri = String(process.env.REACT_APP_SALESFORCE_CRM_BASE_URL)
const salesforce_client_id = String(process.env.REACT_APP_SALESFORCE_CLIENT_ID)
const salesforce_client_secret = String(process.env.REACT_APP_SALESFORCE_CLIENT_SECRET)
const salesforce_secret_token = String(process.env.REACT_APP_SALESFORCE_SECRET_TOKEN)

type StandardLoginDataType = {
  username: string;
  password: string;
}
const AxiosInstanceCreator = (
  BASE_URL: string, 
  customHeaders: any = {
    'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
  }) => {
  // Configuration globale d'Axios
  const instance = axios.create({
    baseURL: BASE_URL, // Remplacez par votre URL de base
    timeout: 60000, // Durée maximale d'attente pour la réponse en millisecondes
    headers: customHeaders,
  });

  // Intercepteur pour les requêtes
  instance.interceptors.request.use(
    (config) => {
      // Vous pouvez ajouter des manipulations avant l'envoi de la requête ici
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Intercepteur pour les réponses
  instance.interceptors.response.use(
    (response) => {
      // Vous pouvez ajouter des manipulations avant de renvoyer la réponse ici
      return response;
    },
    (error) => {
      // Vous pouvez gérer les erreurs ici
      return Promise.reject(error);
    }
  );
  
  return instance;
}

export const getDataFromSalesforce = async (crm_api_key: string, Type: string, Id: string = "") => {
  
  try{
    const instance = AxiosInstanceCreator(aurelia_uri);
    const response = await instance.get(`retrieve_data_by_type?Id=${Id}&token=${crm_api_key}&Type=${Type}`);
    return response
  } catch(error) {
      return error
  }
};

export const createNewAccount = async (data: any) => {
    /**
     * Data example
     * {
        "Name": "Compte 2 Entreprise",
        "BillingCity": "Paris",
        "NumberOfEmployees" : 31000,
        "Phone": "(257) 789-2134",
        "Website": "https://www.mywebsite.com",
        "token": "00DQy000005uK4X!AQEAQHYvJYkGN6MptaYaj3vmzF8hCAgfUPyLhvDrZF9EBkodzDTXCXyIYkeZgyJQQjH3dU.xpELOro34qXPuu5lAGutE54px"
    }
     */
    try{
        const instance = AxiosInstanceCreator(aurelia_uri);
        const response = await instance.post('account', data);
        return response
      } catch(error) {
          // console.error(error)
          return ""
      }
}

export const createNewContact = async (data: any) => {
    /**
     * Data example
     * {
            "Department": "Department4",
            "FirstName" : "Prénom4",
            "LastName" : "Nom 4",
            "Email": "prenom4@omail.fr",
            "Title": "VP",
            "Phone": "(786) 561-3777",
            "Description": "l'entreprise",
            "token": "00DQy000005uK4X!AQEAQHYvJYkGN6MptaYaj3vmzF8hCAgfUPyLhvDrZF9EBkodzDTXCXyIYkeZgyJQQjH3dU.xpELOro34qXPuu5lAGutE54px"
        }
     */
    try{
        const instance = AxiosInstanceCreator(aurelia_uri);
        const response = await instance.post('contact', data);
        return response
      } catch(error) {
          // console.error(error)
          return ""
      }
}

export const sendAccountData2Update = async (data: any) => {
    /**
     * Data example
     * {
        "allOrNone" : false,
        "records" : [{
            "attributes" : {"type" : "Account"}
        }],
        "Name": "New acc 2",
        "New_name": "Acc2 new name",
        "token": "00DQy000005uK4X!AQEAQHYvJYkGN6MptaYaj3vmzF8hCAgfUPyLhvDrZF9EBkodzDTXCXyIYkeZgyJQQjH3dU.xpELOro34qXPuu5lAGutE54px"
        }
     */
    try{
      const instance = AxiosInstanceCreator(aurelia_uri);
      const response = await instance.post('update_account', data);
      return response
    } catch(error) {
        // console.error(error)
        return ""
    }
}

export const sendContactData2Update = async (data: any) => {    
    /**
     * Data example
     * {
        "allOrNone" : false,
        "records" : [{
            "attributes" : {"type" : "Contact"},
            "Department": "New depart 2",
            "FirstName" : "New F3",
            "LastName" : "L3",
            "Email": "newf3l3@promail.fr",
            "Title": "Vice President, CMO",
            "Phone": "(786) 561-3779",
            "Description": "Une Petite description de l'entreprise."
        }],
        "Name" : "Lastname, Firstname",
        "token": "00DQy000005uK4X!AQEAQHYvJYkGN6MptaYaj3vmzF8hCAgfUPyLhvDrZF9EBkodzDTXCXyIYkeZgyJQQjH3dU.xpELOro34qXPuu5lAGutE54px"
        } */
    try{
      const instance = AxiosInstanceCreator(aurelia_uri);
      const response = await instance.post('update_contact', data);
      return response
    } catch(error) {
        // console.error(error)
        return ""
    }
}

export const sfLoginUsernameAndPass = async (data: StandardLoginDataType) => {
  try{
    const myHeaders = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("client_id", salesforce_client_id);
    urlencoded.append("client_secret", salesforce_client_secret);
    urlencoded.append("username", data.username);
    urlencoded.append("password", data.password + salesforce_secret_token);

    const instance = AxiosInstanceCreator(salesforce_uri, myHeaders);
    const response = await instance.post('services/oauth2/token');
    return response
  } catch(error) {
      // console.error(error)
      return ""
  }
}

export const apolloApiVerification = async (apollo_crm_api_key: string) => {
  try{
    const instance = AxiosInstanceCreator(aurelia_uri);

    const response = await instance.get(`auth/verifyApolloKey/${apollo_crm_api_key}`);
    return response
  } catch(error) {
      return error
  }
};

export const hubspotApiVerification = async (hubpsot_crm_api_key: string) => {
  try {
    // const myHeaders = {
    //   "Content-Type": "application/json",
    //   'Authorization': `Bearer ${hubpsot_crm_api_key}`
    // }

    const instance = AxiosInstanceCreator(aurelia_uri);

    const response = await instance.get(`auth/verifyHubspotKey/${hubpsot_crm_api_key}`);
    return response.data; // Renvoie uniquement les données de la réponse
  } catch (error) {
    console.error("Erreur lors de la vérification de l'API HubSpot :", error);
    throw error; // Renvoie l'erreur pour une gestion plus précise en aval
  }
};
