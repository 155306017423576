import React, { useEffect, useState } from "react";
import "../styles/Modal.css"
import { LoaderResponse } from "./Loader";

interface ModalProps {
    header_title: string;
    header_title_bg_color?: string;
    header_title_text_color?: string;
    loading_text?: string;
    loader_color?: string;
    loading_text_color?: string;
    loader_state?: boolean;
    buttonText?: string;
    button_text_color?: string;
    buttonBgColor?: string;
    open: boolean;
    onClose: () => void;
    onValid?: () => void;
    children: JSX.Element;
}

const image_path = "/images/btn/close-button.png"
const ModalPage: React.FC<ModalProps> = ({
        header_title, 
        header_title_bg_color,
        header_title_text_color = "white",
        loading_text = "Validation en cours...", 
        loader_color = "green",
        loading_text_color = "black",
        loader_state = true,
        buttonText = "Valider", 
        buttonBgColor = "#1d572f",
        button_text_color = "white",
        open, 
        onClose, 
        onValid, 
        children
    }) => {
    const [loader, setLoader] = useState(loader_state)
    const [btnBgc, setBtnBgc] = useState("")

    const clickValidButton = () => {
        if(onValid) {
            onValid()
            setLoader(loader_state ? true : false)
        }else {
            setLoader(false)
        }
    }

    const clickCloseButton = () => {
        if(onClose){
            onClose()
            setLoader(false)
        }
    }

    const updateBgColor = (e: string) => {
        if(e === "enter") setBtnBgc("white")
        if(e === "leave") setBtnBgc(buttonBgColor) 
    }

    useEffect(() => {
        setLoader(false)
        setBtnBgc(buttonBgColor)
    }, [open])

    return (
        <div className={`${"modal"} ${open ? "display-block" : "display-none"}`}>
            <div className="modal-main">
                <div className="modal-head" style={{ background : header_title_bg_color ? header_title_bg_color : "#34844ce3", color: header_title_text_color}}>
                    <div id="modal-close-btn">
                        <button style={{background: "none", border: "none", cursor: "pointer"}} onClick={clickCloseButton}>
                            <img src={image_path} alt="" width={30} height={30} />
                        </button>
                    </div>
                    <h1>{header_title}</h1>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="btn-container">
                    {
                        loader ? (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <LoaderResponse colorValue={loader_color} w={25} h={25} />
                                <p style={{color: loading_text_color}}>{loading_text}</p>
                            </div>
                        ) : (
                            <button 
                                type="button" 
                                id="modal-form-btn" 
                                style={{ background: btnBgc, color: button_text_color}} 
                                onClick={clickValidButton} 
                                onMouseEnter={() => updateBgColor("enter")}
                                onMouseLeave={() => updateBgColor("leave")}
                                >{buttonText}</button>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default ModalPage