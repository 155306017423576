import "../styles/IconeButton.css"

// Ajoutez une interface pour spécifier le type de propriétés que SwitchButton accepte
interface IconButtonProps {
  alt_text: string;
  w: number;
  h: number;
  source_image: string;
  func_action: () => void;
}

export const CIconButton: React.FC<IconButtonProps> = ({alt_text="", w=40, h=40, source_image, func_action}) => {
  
  return ( 

    <button className="icone-btn-container" onClick={func_action} title='Annuler'>
      <div id='icone-btn-image-container'>
        <img src={source_image} alt={alt_text} width={w} height={h} />
      </div>
    </button>
  ) 
}