import axios from "axios";
import { PYTHON_API_URL, MODEL_TITLE } from "../constants/ArrayData";

const AxiosInstanceCreator = (BASE_URL) => {
  const instance = axios.create({
    baseURL: PYTHON_API_URL[MODEL_TITLE.indexOf("Aurélia")], //*/"http://127.0.0.1:8000", 
    timeout: 60000
  })

  return instance
}

export const login = async (options, model_name) => {
  try {
    const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(model_name)]
    const instance = AxiosInstanceCreator(model_for_uri);
    const response = await instance.post('/auth/signin', options, {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchLinkedinData = async (code, model_name) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(model_name)]
  
  try {
    const instance = AxiosInstanceCreator(model_for_uri);
    const response = await instance.get('/auth/linkedin-callback/' + code, {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
      }
    })

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchProfileData = async (endpoint, token, token_type, model_name) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(model_name)]
  try {
    const instance = AxiosInstanceCreator(model_for_uri);
    const response = await instance.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token_type} ${token}`
      }
    })

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const checkToken = async (token, model_name) => {
  const model_for_uri = PYTHON_API_URL[MODEL_TITLE.indexOf(model_name)]
  try{
    const instance = AxiosInstanceCreator(model_for_uri);
    const response = await instance.get('/auth/check-token', {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })

    return response.data;
  } catch (error) {
    throw error;
  }
}
