import React, { useCallback, useEffect, useState } from 'react';
import CheckboxImageView from '../../components/ImageCheckbox';
import { LoaderResponse } from '../../components/Loader';
import StripeService from '../../services/StripeServices';

import "../../styles/Tarifs.css"
import { CIconButton } from '../../components/IconButton';
import { saveUserSubscriptionData } from '../../api/tarifs_api';
import { USER_INFO_KEY } from '../../constants/StoreKey';
import Modal from '../../components/Modal';
import PageGenerationProps from '../../interface/PageInterface';
import { COLORS } from '../../constants/ColorsData';

const PageTarifs: React.FC<PageGenerationProps> = ({setPageNameToLoad}) => {
    const close_btn = "/images/btn/cancel-btn.png"
    const greencheckmark = "/images/icone/green-stars-check.png"

    const search_desc = "Recherche et analyse d'informations par rapport à une activité d'entreprise cible et obtenir des résultats plus afinés et précis"
    const contact_desc = "Trouver les informations de contact nécessaires."
    const llm_desc = "Profiter de l'interaction avec Aurélia en tant que Mails sails specialist, pour rédiger des mails personnalisés"
    const crm_desc = "Remplit et met à jour automatiquement votre CRM avec les informations trouvées par Aurélia Search, Aurélia Contact, et Aurélia LLM."

    const [requestStatus, setRequestStatus] = useState(false);
    const [subsRequest, setSubsRequest] = useState(false);
    const [runStateChecking, setRunStateChecking] = useState(false);

    const [trialIsAvailable, setTrialAvailable] = useState({
        trial_status: true,
        trial_date_begin: ""
    });
    
    const [userCanSubscribe, setUserCanSubscribe] = useState(false)

    const [checkboxOffertState, setCheckboxOfferState] = useState([
        false, false, false, false
    ])

    const [offerDataValue, setOfferDataValue] = useState({
        Aurelia_search: {
            title: "Aurélia Search",
            price: "15€ / mois",
            isChoosed: false,
            priceId: 'price_1P1QCEP49PQeFCnwLyjNOL8l'
        },
        Aurelia_contact: {
            title: "Aurélia Contact",
            price: "5€ / mois",
            isChoosed: false,
            priceId: 'price_1P1QClP49PQeFCnwzQOXa9eh'
        },
        Aurelia_llm: {
            title: "Aurélia LLM",
            price: "1000 crédits = 0,5ct/crédit",
            isChoosed: false,
            priceId: 'price_1P1QFXP49PQeFCnwgwRPshpY'
        },
        Aurelia_crm: {
            title: "Aurélia CRM",
            price: "10€ / mois",
            isChoosed: false,
            priceId: 'price_1P1QNmP49PQeFCnwjpBrhgxp'
        }
    })

    const [showModal, setShowModal] = useState(false)


    const closeModal = () => {
        setShowModal(!showModal)
    }
    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const isOneModuleIsChoosed = useCallback(() => {
        let counter = -1;
        checkboxOffertState.forEach((item, index) => {
            if (item) {
                counter = index;
                setUserCanSubscribe(item);
            }
        });

        if (counter < 0) setUserCanSubscribe(false);
    }, [checkboxOffertState, setUserCanSubscribe]);
    
    const updateModuleOfferState = (state: boolean, module_name: string) => {
        setRunStateChecking(state)

        switch(module_name){
            case "Aurelia_search":
                setOfferDataValue(previousValue => ({
                    ...previousValue, Aurelia_search: {
                        isChoosed: state, 
                        title: previousValue.Aurelia_search.title, 
                        price: previousValue.Aurelia_search.price,
                        priceId: previousValue.Aurelia_search.priceId
                    }
                }))

                setCheckboxOfferState([state, false, false, false])
                break
            case "Aurelia_contact":
                setOfferDataValue(previousValue => ({
                    ...previousValue, Aurelia_contact: {
                        isChoosed: state, 
                        title: previousValue.Aurelia_contact.title, 
                        price: previousValue.Aurelia_contact.price,
                        priceId: previousValue.Aurelia_contact.priceId
                    }
                }))

                setCheckboxOfferState([ false, state, false, false])
                break
            case "Aurelia_llm":
                setOfferDataValue(previousValue => ({
                    ...previousValue, Aurelia_llm: {
                        isChoosed: state, 
                        title: previousValue.Aurelia_llm.title, 
                        price: previousValue.Aurelia_llm.price,
                        priceId: previousValue.Aurelia_llm.priceId
                    }
                }))

                setCheckboxOfferState([false, false, state, false])
                break
            case "Aurelia_crm":
                setOfferDataValue(previousValue => ({
                    ...previousValue, Aurelia_crm: {
                        isChoosed: state, 
                        title: previousValue.Aurelia_crm.title, 
                        price: previousValue.Aurelia_crm.price,
                        priceId: previousValue.Aurelia_crm.priceId
                    }
                }))

                setCheckboxOfferState([false, false, false, state])
                break
            default:
                break
        }
    }

    const trialPreparationView = () => {
        return <div className="trial-modal-container">
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex'}}>
                    <h1 style={{padding: 10, fontSize: 40}}>10</h1>
                    <p style={{padding: 10}}><b>crédits gratuits</b> offertes pour vous afin de profiter des 4 modules d'Aurélia</p>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}></div>

                    <div className='trial-module-list-container'>
                        <div style={{display: 'flex', flex: 1}}>
                            <div className='trial-module-list-img-container'>
                                <img  src={greencheckmark} alt='' width={20} height={20} />
                            </div> 
                            <div className='trial-module-list-module-title-container'>
                                <label>Aurélia Search</label>
                            </div>
                        </div>
                        <div style={{display: 'flex', flex: 1}}>
                            <div className='trial-module-list-img-container'>
                                <img  src={greencheckmark} alt='' width={20} height={20} />
                            </div> 
                            <div className='trial-module-list-module-title-container'>
                                <label>Aurélia Contact</label>
                            </div>
                        </div>
                        <div style={{display: 'flex', flex: 1}}>
                            <div className='trial-module-list-img-container'>
                                <img  src={greencheckmark} alt='' width={20} height={20} />
                            </div> 
                            <div className='trial-module-list-module-title-container'>
                                <label>Aurélia LLM</label>
                            </div>
                        </div>
                        <div style={{display: 'flex', flex: 1}}>
                            <div className='trial-module-list-img-container'>
                                <img  src={greencheckmark} alt='' width={20} height={20} />
                            </div> 
                            <div className='trial-module-list-module-title-container'>
                                <label>Aurélia CRM</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const trialPreparation = () => {
        setSubsRequest(true)
        const ls_user_data = localStorage.getItem(USER_INFO_KEY)
        
        if(ls_user_data) {
            const ls_user_data_value = JSON.parse(ls_user_data)
            // Cette fonction sera exécutée une seule fois au démarrage du composant
            saveUserSubscriptionData({
                user_id: ls_user_data_value._id.$oid,
                email: ls_user_data_value.email,
                credit_count: 10
            }).then((rep: any) => {
                setShowModal(!showModal)
                goToNextPage()
            }).catch((e) => console.error(e))
        }
    }

    const goToNextPage = () => {
        setPageNameToLoad("CRM")
    }

    /**
     * Permettre au client de visualiser son abonnement sur Stripe
     */
    const portalClient = () => {
        setRequestStatus(true)
        const userInfoData = JSON.parse(String(localStorage.getItem(USER_INFO_KEY)));

        StripeService.portalSession(userInfoData.email).then((data) => {
                
            window.location = data.url;
            setRequestStatus(false)

        }).catch(err => {
            console.log(err)
            setRequestStatus(false)
        });
    }

    /**
     * Récupérer les informations d'abonnement du client sur Stripe
     */
    const getCustomerSubscription = () => {
        const userInfoData = JSON.parse(String(localStorage.getItem(USER_INFO_KEY)));

        StripeService.getCustomers(userInfoData.email).then((data) => {
            console.log("User subs data => ", data);
            
            setRequestStatus(false)

        }).catch(err => {
            console.log(err)
            setRequestStatus(false)
        });
    }

    const validateAndSave = () => {
        setRequestStatus(true)

        const valuesFiltered = Object.values(offerDataValue).filter((item) => item.isChoosed && item.title !== 'Aurélia LLM');

        const priceIds = valuesFiltered.map((item) => item.priceId);

        const userId = localStorage.getItem('userID');
        const userInfoData = JSON.parse(String(localStorage.getItem(USER_INFO_KEY)));

        if (priceIds.length > 0) {
            StripeService.createSubscription(priceIds, userId, userInfoData).then((data) => {
                // console.log("Data return from stripe : create subs => ", data)
                window.location = data.url;
                setTimeout(() => {
                    setRequestStatus(false)
                }, 2000)
    
            }).catch(err => {
                console.log(err)
                setRequestStatus(false)
            });
        } else if (offerDataValue.Aurelia_llm.isChoosed) {
            const priceLLm = [offerDataValue.Aurelia_llm.priceId];
            StripeService.createPayment(priceLLm, userInfoData).then((data) => {
                // console.log("Data return from stripe: createPayment => ", data)
                window.location = data.url;
                setTimeout(() => {
                    setRequestStatus(false)
                }, 2000)
    
            }).catch(err => {
                console.log(err)
                setRequestStatus(false)
            });
        }
    }

    useEffect(() => {
        isOneModuleIsChoosed()
    }, [runStateChecking, isOneModuleIsChoosed])

    
    return (
        <>
            {/* <div style={{display: "flex", flex: 1, justifyContent: "center"}}> */}
                {/* <button style={{ width: 250, padding: "5px 15px" }} id="form-btn-portal" onClick={portalClient}>Mon espace</button> */}
                <div className='tarif-checkbox-img-container'>
                    <CheckboxImageView 
                        showInputKey={updateModuleOfferState} 
                        image_name='Aurelia_search' 
                        label_text={offerDataValue.Aurelia_search.title} 
                        text_description={search_desc} 
                        button_name='Choisir' 
                        Price={offerDataValue.Aurelia_search.price} />
                    <CheckboxImageView 
                        showInputKey={updateModuleOfferState} 
                        image_name='Aurelia_contact' 
                        label_text={offerDataValue.Aurelia_contact.title} 
                        text_description={contact_desc} 
                        button_name='Choisir'
                        Price={offerDataValue.Aurelia_contact.price}/>
                    <CheckboxImageView 
                        showInputKey={updateModuleOfferState} 
                        image_name='Aurelia_llm' 
                        label_text={offerDataValue.Aurelia_llm.title} 
                        text_description={llm_desc} 
                        button_name='Choisir'
                        Price={offerDataValue.Aurelia_llm.price}/>
                    <CheckboxImageView 
                        showInputKey={updateModuleOfferState} 
                        image_name='Aurelia_crm' 
                        label_text={offerDataValue.Aurelia_crm.title} 
                        text_description={crm_desc} button_name='Choisir'
                        Price={offerDataValue.Aurelia_crm.price}/>
                </div>

                <div style={{display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    {
                        requestStatus ? (
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <LoaderResponse colorValue={COLORS.jauneClaire} />
                                <p style={{color: 'black'}}>Vérification en cours...</p>
                                <CIconButton alt_text={''} w={25} h={25} source_image={close_btn} func_action={() => setRequestStatus(false)} />
                            </div>
                        ) : (
                            // trialIsAvailable.trial_status ? (
                            //     <div className="tarif-container">
                            //         <div className="tarif-btn-container">
                            //             <button 
                            //                 id="form-btn" 
                            //                 // onClick={toggleModal} 
                            //                 onClick={() => {}} 
                            //                 className="tarif-btn-verification">Essayer gratuitement
                            //             </button>
                            //         </div>
                            //         <div className="tarif-btn-container">
                            //             <button 
                            //                 id="form-btn" 
                            //                 title={!userCanSubscribe ? "Choisissez au moins un module pour débloquer" : "Prêt à s'abonner ?"}
                            //                 disabled={!userCanSubscribe} 
                            //                 style={{ cursor: !userCanSubscribe ? "not-allowed" : "pointer"}} 
                            //                 onClick={validateAndSave} className="tarif-btn-verification">S'abonner directement
                            //             </button>
                            //         </div>
                            //     </div>
                            // ) : (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <button 
                                        id="form-btn" 
                                        title={!userCanSubscribe ? "Choisissez au moins un module pour débloquer" : "Prêt à s'abonner ?"}
                                        disabled={!userCanSubscribe} 
                                        style={{ cursor: !userCanSubscribe ? "not-allowed" : "pointer"}} 
                                        onClick={validateAndSave} className="tarif-btn-verification">Valider & souscrire
                                    </button>
                                </div>
                        //     )
                        )
                    }
                </div>
                <Modal open={showModal} header_title="Une semaine d'essai gratuit" onClose={closeModal} onValid={trialPreparation} children={trialPreparationView()}></Modal>
                {/* <div style={{display: 'flex', flex: 3}}></div> */}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {/* <button 
                        id="form-btn" 
                        // title={!userCanSubscribe ? "Choisissez au moins un module pour débloquer" : "Prêt à s'abonner ?"}
                        // disabled={!userCanSubscribe} 
                        // style={{ cursor: !userCanSubscribe ? "not-allowed" : "pointer"}} 
                        onClick={getCustomerSubscription} className="tarif-btn-verification">User data get
                    </button> */}
                </div>
            {/* </div> */}
        </>
    )
}

export default PageTarifs
