import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { USER_ID_KEY } from "../constants/StoreKey";
import { MailData2Send } from "../interface/DataI";
import { UserFormData4MailType, RecipientData, SenderData } from "../type/UserDataEvent";
import { getUserFormHistoryDataFromDB } from "../services/UserServices";
import { LoaderResponse } from "../components/Loader";
import { COLORS } from "../constants/ColorsData";


// const rows: GridRowsProp = [
//   { id: 1, col1: "Hello", col2: "World" },
//   { id: 2, col1: "DataGridPro", col2: "is Awesome" },
//   { id: 3, col1: "MUI", col2: "is Amazing" },
// ];

// const columns: GridColDef[] = [
//   { field: "id", headerName: "ID", width: 15 },
//   { field: "col1", headerName: "Column 1", width: 150 },
//   { field: "col2", headerName: "Column 2", width: 150 },
//   {
//     field: "action",
//     headerName: "Action",
//     width: 180,
//     sortable: false,

//     renderCell: (params) => {
//         const onClick = (e: any) => {
//           const currentRow = params.row;
//           return alert(JSON.stringify(currentRow, null, 4));
//         };

//         return (
//           <Stack direction="row" spacing={2}>
//             <Button variant="outlined" color="primary" size="small" onClick={onClick}>Edit</Button>
//             <Button variant="outlined" color="error" size="small" onClick={onClick}>Delete</Button>
//           </Stack>
//         );
//     },
//   }
// ];

const Mail2GenHistoryPage = () => {
    const [DataHistory, setDataHistory] = useState<Array<UserFormData4MailType>>([])
    const [dataRequestStatus, setDataRequestStatus] = useState(false)
    const [runLoader, setRunLoader] = useState(true)

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", headerAlign: "left", width: 70, headerClassName: "super-app-theme--Open", },
        { field: "activity_domain", headerName: "Domaine d'activité", headerAlign: "left", width: 150, headerClassName: "super-app-theme--Open" },
        { field: "name", headerName: "Déstinataire", headerAlign: "left", width: 200, headerClassName: "super-app-theme--Open" },
        { field: "organization", headerName: "Entreprise", headerAlign: "left", width: 200, headerClassName: "super-app-theme--Open" },
        { field: "job", headerName: "Poste", headerAlign: "left", width: 150, headerClassName: "super-app-theme--Open" },
        // { field: "mail_status", headerName: "Status du mail", headerAlign: "left", width: 200, headerClassName: "super-app-theme--Open" },
        { field: "date", headerName: "Date d'envoi", headerAlign: "left", width: 200, headerClassName: "super-app-theme--Open" },
        { field: "status", headerName: "Status", headerAlign: "left", width: 150 },
        {
            field: "action",
            headerName: "Action",
            width: 180,
            sortable: false,

            renderCell: (params) => {
                const onClick = (e: any) => {
                    const currentRow = params.row;
                    return alert(JSON.stringify(currentRow, null, 4));
                };

                return (
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="primary" size="small" onClick={onClick}>Edit</Button>
                        <Button variant="outlined" color="error" size="small" onClick={onClick}>Re-send</Button>
                    </Stack>
                );
            },
        }
    ];

    const extractUsefulInfo = (data: UserFormData4MailType, ) => {
        const recipient: RecipientData = data.recipient
        // let title_recipient: Array<String> = []

        // const sender: SenderData = data.sender
        // const mail: MailData2Send = data.mail
        const clean_data: any = {}

        // Déstinataire:
        try {
            const act = recipient.activity_domain

            for(let i=0; i < act.length; i++){
                if (act[i].status) {
                    // if(!title_recipient.includes(act[i].title)){
                    clean_data["id"] = data['id']
                    clean_data["activity_domain"] = act[i].title
                    // title_recipient.push(act[i].title)
                    console.log("clean data: ",i, clean_data, "\n")
                    // }
                }
            }
            // title_recipient = []
            
            return clean_data
        }
        catch (e) {
            console.log("Erreur pour: ", e)
            return []
        }
    }

    useEffect(() => {
        const user_id = localStorage.getItem(USER_ID_KEY);
        getUserFormHistoryDataFromDB(user_id)
            .then((res: any) => {
                if (res.body) {
                    const body: Array<UserFormData4MailType> = res.body;
                    if (body.length > 0) {
                        let temp_body: Array<UserFormData4MailType> = [];
                        // console.log("Body: ", body)
                        let count = 0
                        for (let index = body.length - 1; index >= 0; index--) {
                            body[index]["id"] = count;
                            const clean_data = extractUsefulInfo(body[index]);
                            // console.log("Ajout de N°: ", index)
                            temp_body.push(clean_data);
                            count += 1
                        }
                        setDataHistory(temp_body);
                        setDataRequestStatus(true);
                        // console.log("Set state :", dataRequestStatus)
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [dataRequestStatus]); // Tableau vide pour n'exécuter l'effet qu'une seule fois au montage initial
    
    useEffect(() => {
        if (dataRequestStatus) {
            // Le code qui dépend de dataRequestStatus ici
            console.log("Data request status est vrai maintenant :", dataRequestStatus);
            setRunLoader(false)
        }
    }, [dataRequestStatus]);
    

    return (
        <>
            {
                !runLoader ? (
                    <div style={{ height: "98vh", width: "98vw", margin: 15 }}>
                        <DataGrid rows={DataHistory} columns={columns} />
                    </div>
                ) : (
                    DataHistory.length > 0 && (
                        <div style={{ display: "flex", flex: 5, justifyContent: "center", alignItems: "center" }}>
                            <LoaderResponse colorValue={COLORS.noireMoinsSombre} />
                            <p style={{ color: "black", fontWeight: 'bold' }}>Actualisation en cours...</p>
                        </div>
                    )
                )
            }
        </>
        
    )
}

export default Mail2GenHistoryPage

