import { CheckBoxList } from "../interface/DataI";
import CryptoJS from 'crypto-js';

/**
 *  Filtrer les éléments avec status booléen
 *  Concaténation de f--t (par rapport au nombre d'élément du tableau de type CheckBoxList)
 * @param data 
 * @returns combinaison t si status = true, f sinon avec un séparateur par défaut: --
 */
export const convertFormatData2Text = (data: Array<CheckBoxList>, sep: string = "--") => {
    const result_concat = data.map(item => item.status ? "t" : "f").join(sep);
    return result_concat
}

/**
 *  Filtrer les éléments avec status booléen
 *  Concaténation le titre (par rapport au nombre d'élément du tableau de type CheckBoxList)
 * @param data 
 * @returns title$$title ... : combinaison de chaque titre ayant un status true
 */
export const getOnlyTrueStatus = (data: Array<CheckBoxList>, sep: string = "$$") => {
    const filteredTitles = data.filter(item => item.status).map(item => item.title);
    
    if (filteredTitles.length > 1) {
        return filteredTitles.join(sep);
    } else if (filteredTitles.length === 1) {
        return filteredTitles[0];
    } else {
        return "";
    }
}

export const ordonnerTableau = (tableau: string[], ordre_reel_tableau: string[], keysArray: string[]): string[] => {
    // const ordre_reel_tableau = ["Hubspots", "Apollo", "Salesforce", "Google Sheets"];
    // const tableau: string[] = ["Hubspots", "Google Sheets",  "Salesforce","Apollo"];

    // const indiceMap: Record<string, number> = {};
    // ordre_reel_tableau.forEach((element, index) => {
    //   indiceMap[element] = index;
    // });
  
    // // Trier le tableau_value en utilisant le comparateur personnalisé basé sur l'indice
    // tableau_value.sort((a, b) => {
    //   const indexA = indiceMap[tableau[tableau_value.indexOf(a)]];
    //   const indexB = indiceMap[tableau[tableau_value.indexOf(b)]];
    //   return indexA - indexB;
    // });
    
    const result: string[] = [];

    for (let i = 0; i < ordre_reel_tableau.length; i++) {
        const index = tableau.indexOf(ordre_reel_tableau[i]);
        if (index !== -1) {
            result[i] = keysArray[index];
        }
    }

    return result;
  }

/**
 * Crypto function - localstorage
 */

const SECRET_KEY = String(process.env.REACT_APP_ENCRIPT_SECRET_KEY)

/**
 * 
 * @param name la clé dans storage
 * @param data les données à stocker
 */
export const encryptData = (name: string, data: any) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    localStorage.setItem(name, encrypted);
}

/**
 * 
 * @param name la clé dans storage
 * @returns la valeur déchiffrée
 */
export const decryptData = (name: string) => {
    const encrypted = String(localStorage.getItem(name));
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    // console.log("Util: ", name, encrypted, decrypted)
    return decrypted === "" ? "" : JSON.parse(decrypted);
}