import { FC, useEffect } from "react"
import { LoaderResponse } from "../../components/Loader"
import { USER_ID_KEY, USER_INFO_KEY } from "../../constants/StoreKey"
import { getUserCreditPricingInfo } from "../../api/tarifs_api"
import { useParams } from "react-router-dom"
import { Redirect2NewPage } from "../../Helpers/Redirect2"
import { verifyUserTokenValidation } from "../../api/token_api"
import { COLORS } from "../../constants/ColorsData"

interface IDashRedirection {
    url_route?: string;
}

const DashRedirection: FC<IDashRedirection> = ({url_route = ""}) => {
    const params = useParams();

    const page_name = params.page_name
    
    const color = COLORS.jauneClaire

    useEffect(() => {
        const userid = localStorage.getItem(USER_ID_KEY)
        const userInfoData = localStorage.getItem(USER_INFO_KEY)

        var email = ""

        if(userInfoData) {
            email = JSON.parse(userInfoData)['email']
        }

        if(userid && email) {
            verifyUserTokenValidation().then((res_data: any) => {
                // Token est valide
                if(email && res_data && res_data.data && (res_data.data.status === "valid")){
                    // Vérifier si la personne est déjà inscrit pour le tarif
                    getUserCreditPricingInfo(email).then((resp) => {
                        if(resp){ 
                            setTimeout(() => {                  
                                // S'il est inscrit
                                // console.log("page name: ", page_name)
                                if(resp.data && resp.data.body && resp.data.body['subscription_type']) {
                                    if(page_name !== undefined && page_name !== "") {
                                        window.location.replace(`/Aurelia/Dashboard/${page_name}/${userid}`)
                                    }else if (url_route && url_route !== ""){
                                        window.location.replace(`/Aurelia/Dashboard/${url_route}/${userid}`)
                                    } else {
                                        window.location.replace(`/Aurelia/Dashboard/CRM/${userid}`)
                                    }
                                }
                                // sinon
                                else window.location.replace("/welcoming")

                            }, 2000)
                        }
                    } ).catch((e) => console.error("Aurélia Dash Page: Error => ", e))
                }
                // Sinon, redirection vers login
                else {
                    window.localStorage.removeItem(USER_ID_KEY)
                    window.localStorage.removeItem('token')
                    Redirect2NewPage("login")
                }
                
            }).catch((e) => console.error(e))
        }else {
            // console.log("Id: ", userid, ", Email: ", email)
            window.location.replace("/login")
        }
    }, [page_name, url_route])

    return (
        <div style={{
                display: 'flex', 
                flex: 1, justifyContent: 'center', alignItems: 'center', 
                height: '100vh', flexDirection: 
                'column', 
                backgroundImage: `url("/images/fond/sso_main_bg.svg")`, backgroundRepeat: 'no-repeat', backgroundSize: "cover"
            }}>
            <LoaderResponse colorValue={color} w={130} h={130}/>
            <p style={{color: color }}>Veuillez patientez un moment...</p>
        </div>
    )
}

export default DashRedirection
