export const MODEL_TITLE = [
  "Stéphane",
  "Aurélia",
]

export const REAL_MODEL_NAME = [
  "https://ia-generative.giris.fr:8445/stephane",
  "https://models.giris.fr:8444/aurelia",
]

export const REAL_MODEL_NAME2 = [
  "https://ia-generative.giris.fr:8445/stephane",
  "https://models.giris.fr:8444/v2/aurelia",
]

export const PYTHON_API_URL = [
"https://ia-generative.giris.fr:8445", // Stéphane
"https://models.giris.fr:8444", // Aurélia
]

export const MODEL_DESCRIPTION = [
"INGENIEUR AVANT-VENTE",
"EMAIL SALES SPECIALIST",
]

export const MESSAGE = [
  "Le processus est en cours, et les détails de la réunion seront envoyé à votre adresse e-mail. Veuillez le vérifier.",
  "Le processus est en cours, et le résultat sera envoyé à votre adresse e-mail. Veuillez le vérifier à partir de maintenant.",
]

export const MAIL_FORMAT_DATA = [
  {
    title: "Format du mail",
    content: [
      "Métaphore",
      "Réponse à choix multiple",
      "Question ouverte",
      "Étude de cas / Succès client",
      "Offre de contenu gratuit",
      "Invitation à un événement",
      "Quiz personnalisé",
      "Comparaison avant/après",
      "Format contextuel",
      "Défi de 7 jours",
      "Technologies",
      "Healthtech (english)",
      // ""
    ]
  }
]

export const MAIL_TITLE_AND_STATE = [
  {
    title: "Métaphore",
    status: false
  },
  {
    title: "Réponse à choix multiple",
    status: false
  },
  {
    title: "Question ouverte",
    status: false
  },
  {
    title: "Étude de cas / Succès client",
    status: false
  },
  {
    title: "Offre de contenu gratuit",
    status: false
  },
  {
    title: "Invitation à un événement",
    status: false
  },
  {
    title: "Quiz personnalisé",
    status: false
  },
  {
    title: "Comparaison avant/après",
    status: false
  },
  {
    title: "Format contextuel",
    status: false
  },
  {
    title: "Défi de 7 jours",
    status: false
  },{
    title: "Technologies",
    status: false
  },
  {
    title: "Healthtech",
    status: false
  },
  // {
  //   title: "",
  //   status: false
  // }
]

export const INCITATION_DATA = [
  {
    title: "Incitation à la réponse",
    content: [
        "Question Directe",
        "Choix de Disponibilités",
        "Invitation à l'Action avec Ressource Gratuite"
    ]
}
]

export const AUTRES = [
  {
      title: "Accroche",
      content: [
        "Captivant",
        "Intriguant",
        "Engageant"
      ]
  },
  {
      title: "Paragraphe sur la cible",
      content: [
          "Référence à des réalisations récentes ou à des actualités de l'entreprise",
          "Problèmes ou défis spécifiques à l'industrie",
          "Utilisation de leurs propres mots ou citations",
          "Projets ou initiatives spécifiques de l'entreprise",
          "Données ou statistiques sectorielles pertinentes"
      ]
  },
  {
      title: "Incitation à la réponse",
      content: [
          "Question Directe",
          "Choix de Disponibilités",
          "Invitation à l'Action avec Ressource Gratuite"
      ]
  },
  {
      title: "Titre de mail",
      content: [

      ]
  } 
]

export const NAV_MENU: {[key: string]: string} = {
  "CRM": "Configuration du CRM",
  "Destinataire" : "Informations sur la cible",
  "Emetteur": "Vos informations",
  "Mail": "Le format de mail",
  "Campagnes" : "Configurez ou suivez vos campagnes",
  "Tarifs": "Bienvenue sur le choix de nos tarifs actuels"
}

export const NAV_MENU_TITLE = [
  "CRM",
  "Destinataire",
  "Emetteur",
  "Mail",
  "Campagnes",
  "Tarifs"
]

export const LANGUAGE_LIST = [
  {name: 'Anglais', classname: "us"},
  {name: 'Portugais', classname: "pt"}
]