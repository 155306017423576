import React, { useEffect, useState } from "react";
import "../styles/Checkbox.css"
import { CheckBoxList, MailFormat4View } from "../interface/DataI";
import { JOB_CHECKBOX_KEY } from "../constants/StoreKey";
import { ModeSubsType } from "../type/TarifsSubsType";

interface CheckBoxProps {
    title: string;
    data: Array<CheckBoxList>;
    /** Vérifier si une des cases est chochée */
    store_name?: string;
    classNameAdd?: string;
    isAllChecked: (val: boolean) => void;
    displayOnlyCheckedBox?: boolean;
    mode?: ModeSubsType;
}

const Checkbox:React.FC<CheckBoxProps> = ({title, data, store_name = "STORE_NAME", isAllChecked, displayOnlyCheckedBox = false}) => {    
    const [formatMailData, setFormatMailData] = useState<Array<CheckBoxList>>(data)
    // const [checkBoxIsClicked, setCheckBoxIsClicked] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false)

    /**
     * Vérifie si au moins une case est coché ou non
     * @param data Liste checbox à changer le status
     * @returns false: au moins une case coché, true : toutes vides
     */
    const checkboxChecker = (data: Array<CheckBoxList>) => {
        let count = 0
        data.forEach((item: CheckBoxList, index: number) => {
            // Une des case a été coché 
            if (item.status) {
                count += 1
            }
        });
         
        return count === 0 ? false : true
    }

    /**
     * Met à jour les cases qui sont cochées ou décochées
     * @param id 
     */
    const updateCheckBoxStatus = (id: number) => {
        setFormatMailData(prevData => {
            const newData = prevData.map((item, index) => {
                if (index === id) {
                    return {
                        ...item,
                        status: !item.status
                    };
                }
                return item;
            });
            // if(newData.length === 12) newData.pop()
            
            save2Storage(JSON.stringify(newData));
            isAllChecked(checkboxChecker(newData))
            // setCheckBoxIsClicked(checkboxChecker(newData))
            return newData;
        });
    }

    const MailFormatView = (displayChecked: boolean) => {
        let checkboxView: Array<Array<JSX.Element>> = []
        let checkboxView1: Array<JSX.Element> = []
        let checkboxView2: Array<JSX.Element> = []
        let tempArray: Array<JSX.Element> = []
        let Data: Array<MailFormat4View> = [{
            title: "",
            content: []
        }]
        
        Data[0].title = title

        // Ajout du titre
        data.forEach((content, index) => {
            Data[0].content.push(content.title)
        })

        // Si les données d'arrive sont impaires, on ajoute un champ vide
        if(data.length % 2 === 1){
            data.push({title: "", status: false})
            Data[0].content.push("")
        }
        
        for(let idx = 0; idx < Data.length; idx++){
            checkboxView1.push(
                <label key={-1}>{Data[idx]['title']}</label>
            )

            let content = Data[idx]['content']
            const content_len = content.length

            // console.log("content_len = ", content_len, "content",  content)
            let optionView: Array<Array<JSX.Element>> = [];

            for(let opt_idx=0; opt_idx < content_len; opt_idx++){
                optionView.push([])
                // console.log("option: ", content_len , content_len % 2)
                // console.log("option view: ", optionView[content_len % 2])
                if(content[opt_idx] !== "") {
                    // console.info("id=", opt_idx, "c_len=", content_len%2, content[opt_idx])
                    optionView[content_len % 2].push(
                        <div key={opt_idx} 
                            className="checkbox-container"
                            style={data[opt_idx].display ? {
                                cursor: "pointer"
                                } : {
                                    cursor: "not-allowed"
                                }
                            } >
                            <input 
                                className="checkbox-case"
                                style={data[opt_idx].display ? {cursor: 'pointer'} : {cursor: 'not-allowed'}}
                                type="checkbox" 
                                disabled={data[opt_idx].display ? false : true} 
                                checked={formatMailData[opt_idx].status} 
                                onChange={() => {updateCheckBoxStatus(opt_idx)}} />
                            {
                                store_name === JOB_CHECKBOX_KEY ? 
                                    <label style={
                                        data[opt_idx].display ? {fontWeight: "normal", color:  "black", cursor: "pointer"}
                                        : {fontWeight: "normal", color:  "gray", cursor: "not-allowed"}
                                    }>{content[opt_idx].split(" (")[0]}</label>
                                : 
                                    <label style={
                                        data[opt_idx].display ? {fontWeight: "normal", color:  "black", cursor: "pointer"}
                                        : {fontWeight: "normal", color:  "gray", cursor: "not-allowed"}
                                    }>{content[opt_idx]}</label>
                            }
                        </div>
                    )
                } else {
                    optionView[content_len % 2].push(
                        <div key={content_len % 2} className="checkbox-container">
                            
                        </div>
                    )
                }
                
                // Si l'indice est à la moitié, on ajoute dans la vue
                if((opt_idx + 1 ) % (content_len/2) === 0) {
                        checkboxView2.push(
                            <div className="mail-prep-combobox" key={content_len/2 + opt_idx + 1}>
                                {optionView}
                            </div>
                        )
                        optionView = []
                }
            }
            checkboxView.push(checkboxView1)
            
            tempArray.push(
                // <div key={1} className={data.length > 10 ? "mail-prep-right-text checkbox-scroll" : "mail-checkbox-two-section"}>
                <div key={1} className={"mail-checkbox-two-section"}>
                    {checkboxView2}
                </div>
            )
            checkboxView.push(tempArray)
            
            return checkboxView
        }
    }

    /**
     * Sauvegarde temporaire en localstorage
     * @param value2save 
     */
    const save2Storage = (value2save: string) => {
        localStorage.setItem(store_name, value2save)
    }

    useEffect(() => {
        const ls_mail_format = localStorage.getItem(store_name)

        if(!isDataLoaded){
            // Si les données sont stockées dans le storage et que les données du bdd a la même taille que celles du storage
            if(ls_mail_format && data.length === JSON.parse(ls_mail_format)) {
                setFormatMailData(JSON.parse(ls_mail_format))
                setIsDataLoaded(true)
                // console.log("LS check formatdata: ", JSON.parse(ls_mail_format))
            }else {
                // Si la taille des données arrivées
                if(data.length >= formatMailData.length){
                    setFormatMailData(data)
                    setIsDataLoaded(true)
                    // console.log("Incoming check data from source: ", formatMailData)
                }
            }
        }
    }, [data, formatMailData.length, store_name, isDataLoaded])
    
    return (
        <>
            <div className="mail-prep-right-text">
                {isDataLoaded && MailFormatView(displayOnlyCheckedBox)}
            </div>
        </>
    )
}

export default Checkbox