import axios from "axios";
import { BASE_URL, X_API_KEY } from "../utils/constants";
import { PYTHON_API_URL, MODEL_TITLE } from "../constants/ArrayData";

const instance = axios.create({
  baseURL: PYTHON_API_URL[MODEL_TITLE.indexOf("Aurélia")],//*/"http://127.0.0.1:8000",
  timeout: 60000
})

export const updateUserMailForm4Aurelia = async (options) => {
  try {
    const response = await instance.post('/mail/saveAllUserDataMailFormat', options, {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateRecipientOrSenderData = async (options) => {
  try {
    const response = await instance.post('/mail/updateRSDataMailFormat', options, {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const verifyUserExists = async (id) => {
  try {
    const response = await instance.get('/user/verifyUser/'.concat(id), {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getUserEmailDataFormatById = async (id) => {
  try {
    const response = await instance.get('/user/getUserAllMailFormatData/'.concat(id), {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Informations de campagne sans le mail généré
export const getUserCampaignHistory = async (id) => {
  try {
    const response = await instance.get('/user/getCampaignHistory/'.concat(id), {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Tous les informations de campagnes : nom, entreprise, poste, mail généré
export const getUserDetailedCampaignHistory = async (id) => {
  try {
    const response = await instance.get('/user/getAllDetailsCampaignHistory/'.concat(id), {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getDomainDataFromDB = async (key) => {
  try {
    const response = await instance.get('/db/getDomainList/'.concat(key), {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getDomainDataFromDBList = async () => {
  try {
    const response = await instance.get('/db/getDomainList', {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getJobListDataFromDB = async () => {
  try {
    const response = await instance.get('/db/getJobList', {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getLocationDataFromDB = async () => {
  try {
    const response = await instance.get('/db/getLocation', {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getTechListDataFromDB = async () => {
  try {
    const response = await instance.get('/db/getTechList', {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getMailFormatListDataFromDB = async () => {
  try {
    const response = await instance.get('/db/getMailFormatList', {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getUserFormHistoryDataFromDB = async (userid) => {
  try {
    const response = await instance.get('/history/getAllUserMailHistoryData/'.concat(userid), {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getTargetPersonEmailAdress = async (data) => {
  try {
    const response = await instance.post("user/searchTargetPersonEmailAddress/", data, {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const runSendingMail = async (data) => {
  try {
    const response = await instance.post("/campagn/resendMail/", data, {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const runFollowUp = async (data) => {
  try {
    const response = await instance.post("/campagn/followup/", data, {
      headers: {
        'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
        'Content-Type': 'application/json',
        'x-api-key': `${X_API_KEY}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}