import React from "react";
import PageGenerationProps from "../../interface/PageInterface";
import Recipient from "./Destinataire";
import Sender from "./Emetteur";
import MailPreparation from "./Mail";
import CRMChoice from "./CRM";
import Campagne from "./Campagne";
import PageTarifs from "./Tarifs";
import CampaignHistoryPage from "../UserSpace/CampaignHistoryPage";

const AureliaPage:React.FC<PageGenerationProps> = ({userid, page_name, description_name, creditValue, creditUpdater, setPageNameToLoad}) => {
    const displayCorrectPage = () => {
        switch(page_name){
            case "Destinataire":
                return <Recipient userid={userid} page_name={page_name} description_name={description_name} creditValue={creditValue} creditUpdater={creditUpdater} setPageNameToLoad={setPageNameToLoad} />
            case "Emetteur":
                return <Sender userid={userid} page_name={page_name} description_name={description_name} creditValue={creditValue} creditUpdater={creditUpdater} setPageNameToLoad={setPageNameToLoad} />
            case "Mail":
                return <MailPreparation userid={userid} page_name={page_name} description_name={description_name} creditValue={creditValue} creditUpdater={creditUpdater} setPageNameToLoad={setPageNameToLoad} />
            case "CRM":
                return <CRMChoice userid={userid} page_name={page_name} description_name={description_name} creditValue={creditValue} creditUpdater={creditUpdater} setPageNameToLoad={setPageNameToLoad} />
            case "Campagnes":
                return <Campagne userid={userid} page_name={page_name} description_name={description_name} creditValue={creditValue} creditUpdater={creditUpdater} setPageNameToLoad={setPageNameToLoad} />
            case "Tarifs":
                return <PageTarifs userid={userid} page_name={page_name} description_name={description_name} creditValue={creditValue} creditUpdater={creditUpdater} setPageNameToLoad={setPageNameToLoad} />
            case "CampaignHistory":
                return <CampaignHistoryPage />
            default:
                return <></>
        }
    }
    
    return (
        <>
            {displayCorrectPage()}
        </>
    )
}

export default AureliaPage