// StripeService.js

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const apiKey = process.env.REACT_APP_XAPIKEY;

const headers = {
  'Content-Type': 'application/json',
  'x-api-key': apiKey
};

// Function to handle responses
const handleResponse = (response) => {
  if (response.ok) {
    return response.json();
  }
  throw new Error('Network response was not ok.');
};

const StripeService = {
  createSubscription: async (priceIds, userId, userInfoData) => {
    const body = JSON.stringify({ priceIds, userId, userInfoData });

    return fetch(`${API_BASE_URL}/subscription`, {
      method: 'POST',
      headers,
      body,
    }).then(handleResponse);
  },

  portalSession: async (email) => {
    // Assuming Portal session endpoint is a simple GET request
    const body = JSON.stringify({ email });

    return fetch(`${API_BASE_URL}/subscription/portal-session`, {
      method: 'POST',
      headers,
      body
    }).then(handleResponse);
  },

  createPayment: async (priceIds, userInfoData) => {
    const body = JSON.stringify({ priceIds, userInfoData });

    return fetch(`${API_BASE_URL}/subscription/payment`, {
      method: 'POST',
      headers,
      body,
    }).then(handleResponse);
  },

  getProducts: async (productDetails) => {
    const body = JSON.stringify(productDetails);

    return fetch(`${API_BASE_URL}/subscription/products`, {
      method: 'POST',
      headers: headers,
      body,
    }).then(handleResponse);
  },

  getPrices: async (priceDetails) => {
    const body = JSON.stringify(priceDetails);

    return fetch(`${API_BASE_URL}/subscription/prices`, { // Adjust URL if needed
      method: 'POST',
      headers: headers,
      body,
    }).then(handleResponse);
  },

  getCustomers: async (email) => {
    const body = JSON.stringify({ email });

    return fetch(`${API_BASE_URL}/subscription/customers`, { // Adjust URL if needed
      method: 'POST',
      headers: headers,
      body,
    }).then(handleResponse);
  },

  getUser: async (userDetails) => {
    const body = JSON.stringify(userDetails);

    return fetch(`${API_BASE_URL}/users`, {
      method: 'POST',
      headers,
      body,
    }).then(handleResponse);
  }
};

export default StripeService;
