import { useState } from 'react';
import { Redirect2NewPage } from '../Helpers/Redirect2';
import "../styles/ImageButton.css"
import { CrmType } from '../type/CrmType';

// Ajoutez une interface pour spécifier le type de propriétés que SwitchButton accepte
interface ImageButtonProps {
    id?: string;
    title: CrmType;
    w?: number;
    h?: number;
    url?: string;
    model_name?: string;
    disabled?: boolean;
    showInputKey: (status: boolean, title: CrmType) => void;
  }

export const Imagebutton: React.FC<ImageButtonProps> = ({title, id, w = 35, h=35, url, model_name = "", disabled = false, showInputKey}) => {
  const image_path = "/images/icone/crm/" + title + ".png"
  
  const handleClick = () => {
    showInputKey(true, title)
      // Ouvrir une nouvelle fenêtre avec l'argument
      // Redirect2NewPage(model_name, url, id)
  };
  
  return ( 

    <button disabled={disabled} className="img-btn-container" onClick={handleClick} style={{ cursor: disabled ? "default" : "pointer"}}>
      <div id='img-btn-image-container'>
        <img src={image_path} alt={title} width={w} height={h} />
      </div>
      <div id='img-btn-text-container'>
        <p>{title}</p>
      </div>
    </button>
  ) 
}