import axios from 'axios';
import { MODEL_TITLE, PYTHON_API_URL } from '../constants/ArrayData';

const pricing_uri = PYTHON_API_URL[MODEL_TITLE.indexOf("Aurélia")] + "/pricing/" //*/ "http://127.0.0.1:8000/pricing/"
const token = String(localStorage.getItem("token"))

const AxiosInstanceCreator = (BASE_URL: string, TOKEN: string = token) => {
  // Configuration globale d'Axios
  const instance = axios.create({
    baseURL: BASE_URL, // Remplacez par votre URL de base
    timeout: 20000, // Durée maximale d'attente pour la réponse en millisecondes
    headers: {
      'X-App-Origin': process.env.REACT_APP_ORIGIN_NAME,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`,
      // Vous pouvez ajouter d'autres en-têtes ici si nécessaire
    },
  });

  // Intercepteur pour les requêtes
  instance.interceptors.request.use(
    (config) => {
      // Vous pouvez ajouter des manipulations avant l'envoi de la requête ici
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Intercepteur pour les réponses
  instance.interceptors.response.use(
    (response) => {
      // Vous pouvez ajouter des manipulations avant de renvoyer la réponse ici
      return response;
    },
    (error) => {
      // Vous pouvez gérer les erreurs ici
      return Promise.reject(error);
    }
  );
  
  return instance;
}

export const get = async (user_id: string) => {
  
  try{
    const instance = AxiosInstanceCreator(pricing_uri);
    const response = await instance.get(`getUserMailHistory?user_id=${user_id}`);
    return response
  } catch(error) {
      return error
  }
};

export const saveUserSubscriptionData = async (data: any) => {
  try{
      const instance = AxiosInstanceCreator(pricing_uri, token);
      const response = await instance.post('saveSubscriptionInformation', data);
      return response
    } catch(error) {
        // console.error(error)
        return ""
    }
}

export const updateCredit = async (data: any) => {
    try{
        const instance = AxiosInstanceCreator(pricing_uri, token);
        const response = await instance.post('creditDebit', data);
        return response
      } catch(error) {
          // console.error(error)
          return ""
      }
}

export const getUserCreditPricingInfo = async (user_id: string) => {    
    try{
      const instance = AxiosInstanceCreator(pricing_uri, token);
      const response = await instance.get(`retrieveUserCreditInfo/${user_id}`);
      return response
    } catch(error) {
        // console.error(error)
        return ""
    }
}

export const checkIfUserIsInTrialSession = async (user_id: string) => {    
    try{
      const instance = AxiosInstanceCreator(pricing_uri, token);
      const response = await instance.get(`checkUserSessionType/${user_id}`);
      return response
    } catch(error) {
        // console.error(error)
        return ""
    }
}