export const COLORS = {
    vertClaire: "#5BFF8796",
    texteVertClaire: "#B6FFCB",
    vertFonce: "#0E2627",
    vertFonceTransp: "#367B4763",
    vertUtilisateur: "#367B47CC",
    vertFonceAssistant:"#104239EB",
    bleuClaireVerdatre: "#C2FFC4",
    bleuVertClaire: "rgb(126, 200, 169)",
    bleuClaireMoinsVert: "#a1c4c49c",
    gris: "#d0d0d05e",
    rouge: "#d01212",
    colorBlue: "#00aeef",
    colorBlueDark: "#0d2035",
    submenuWidth: "180px",
    jauneClaire: "#FCE77B",
    jauneSombre: "#BDB58C",
    grisClaire: "#E6E6E640",
    grisSombre: "#2D2914E5",
    violet: "#817CFC",
    noireMoinsSombre: "#363552"
}