import React, { useState, useRef, useEffect } from 'react';
import '../../styles/CodeVerificationPage.css';

import { KEY_CODE, USER_ID_KEY, USER_INFO_KEY } from '../../constants/StoreKey';
import EmailService from '../../services/EmailServices';
import { LoaderResponse } from '../../components/Loader';
import { encryptData } from '../../utils/util_function';

const CodeVerificationPage2: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
    const aurelia_logo = "/images/aurelia_logo.svg"

    const [email_addr, setEmail_addr] = useState("")
    const [inputValues, setInputValues] = useState<string>("");
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [userInfoData, setUserInfoData] = useState()

    const [runLoader, setRunLoader] = useState(false);

    const inputRefs = useRef<(HTMLInputElement | null)>(null);

    useEffect(() => {
        const ls_userData = localStorage.getItem(USER_INFO_KEY)

        if(ls_userData) {
            const ls_userDataValue = JSON.parse(ls_userData)
            setEmail_addr(ls_userDataValue['email'])
            setUserInfoData(ls_userDataValue)
        }
        
        // Enable/disable button based on whether all inputs are filled
        setIsButtonEnabled(inputValues.length === 6);
    }, [inputValues]);

    const handleChange = (value: string) => {
        if(Number(value)){
            setInputValues(value);
        }
    };

    const handleValidate = () => {
        setRunLoader(true)

        // console.log('Validating:', inputValues, ", email ", email_addr);
        EmailService.validateCode(email_addr, inputValues).then((rep) => {
            if(userInfoData) {
                // console.log("Code: ", inputValues, ", rep: ", rep)
                localStorage.setItem('userRole', "user");
                localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfoData));
                localStorage.setItem(USER_ID_KEY, userInfoData['_id']['$oid']);
                
                encryptData(KEY_CODE, inputValues)
                window.location.replace("/")
            }
          }).catch((e) => {
            console.error(e)
            setRunLoader(false)
          })
    };

    return (
        <div className="code-verif-modal-overlay">
            <div className="code-verif-modal">
                <h2 className='code-verif-title'>Bienvenue sur Aurélia</h2>
                <div className='code-verif-logo'>
                    <img src={aurelia_logo} alt='' width={120} height={120} />
                </div>
                <div className="title-container">
                    <p>Un code à 6 chiffres a été envoyé à votre adresse e-mail : <b>{email_addr}</b></p>
                </div>
                <div className="input-container">
                        <input
                            // id="input-six-number"
                            ref={inputRefs}
                            type="text"
                            maxLength={6}
                            // value={value}
                            onChange={e => handleChange(e.target.value)}
                            className="input-square2 input_text"
                            placeholder='Code à 6 chiffres'
                            onKeyDown={handleValidate}
                        />
                </div>
                <div className='validate-button-container'>
                    <button className="validate-button" onClick={handleValidate} disabled={!isButtonEnabled}>
                        {
                            runLoader ? (
                                <div style={{display :"flex", flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <LoaderResponse colorValue='white' w={20} h={20} />
                                    <p style={{fontWeight: 'normal'}}>Verification</p>
                                </div>
                            ) : (
                                "Valider"
                            )
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CodeVerificationPage2;
