import { useState } from 'react';

import "../styles/ImageCheckbox.css"
import "../styles/Dash.css"

// Ajoutez une interface pour spécifier le type de propriétés que SwitchButton accepte
interface CheckboxImageProps {
    id?: string;
    label_text: string;
    text_description?: string;
    button_name?: string;
    image_name: string;
    w?: number;
    h?: number;
    url?: string;
    model_name?: string;
    showInputKey: (status: boolean, image_name: string) => void;
    Price?: string;
  }

const CheckboxImageView: React.FC<CheckboxImageProps> = ({id, label_text="Titre", text_description="Description", image_name="Image", button_name="Ajouter", w = 70, h=70, url, model_name = "", showInputKey, Price}) => {
  const image_path = "/images/price/" + image_name + ".png"
  const [isChecked, setIsChecked] = useState(false)
  
  const handleClick = () => {
    showInputKey(true, image_name)
  };
  
    const updateCheckBoxStatus = (opt_idx: React.ChangeEvent<HTMLInputElement>): void => {
      const temp_value = !isChecked
      showInputKey(temp_value, image_name)
      setIsChecked(temp_value)
    }

  return ( 

    <div className="img-checkbox-main-container" >
        <div className="img-checkbox-cardimg-container">
            <img src={image_path} alt={image_name} width={w} height={h} />
        </div>

        <div className="img-checkbox-desc-container">
            <p id='img-checkbox-label-title'>{label_text}</p>
            <p id='img-checkbox-label-desc'>{text_description}</p>
        </div>

        {/* <label id="price">{Price}</label> */}
        <label id="price">{}</label>
        <button disabled={true} className="img-checkbox-input-label-container" onClick={(opt_idx: any) => updateCheckBoxStatus(opt_idx)}>
            <input disabled={true} type="checkbox" className='checkbox-case' checked={isChecked} onChange={(opt_idx) => updateCheckBoxStatus(opt_idx)} />
            <label style={{fontWeight: "bold"}}>{button_name}</label>
        </button>
    </div>
  ) 
}

export default CheckboxImageView