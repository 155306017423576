// EmailService.js

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const apiKey = process.env.REACT_APP_XAPIKEY;

const headers = {
  'Content-Type': 'application/json',
  'x-api-key': apiKey
};

// Function to handle responses
const handleResponse = (response) => {
  if (response.ok) {
    return response.json();
  }
  throw new Error('Network response was not ok.');
};

const EmailService = {
  send: async (email, userInfoData) => {
    const body = JSON.stringify({ email, userInfoData });

    return fetch(`${API_BASE_URL}/email-sender`, {
      method: 'POST',
      headers,
      body,
    }).then(handleResponse);
  },

  validateCode: async (email, code) => {
    const body = JSON.stringify({ email, code });

    return fetch(`${API_BASE_URL}/email-sender/verify`, {
      method: 'POST',
      headers,
      body,
    }).then(handleResponse);
  },

  
};

export default EmailService;
